export default [
    {
        path: '/liangzhushop/shoppingcard',
        component: () => import('@/components/shoppingCard/index'),
        name: 'shoppingCard',
        meta: {
            title: '购物车',
            keepAlive: false,
            tabbarShow: true,
            active: 2
        }
    },
]