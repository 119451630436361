export default [
    {
        path: '/liangzhushop/user',
        component: () => import('@/components/user/index'),
        name: 'user',
        meta: {
            title: '用户中心',
            keepAlive: true,
            tabbarShow: true,
            active:3
        }
    },
    {
        path: '/liangzhushop/addressmanagement',
        component: () => import('@/components/user/addressManagement'),
        name: 'adressManagement',
        meta: {
            title: '地址管理',
            keepAlive: false,
            tabberShow: false
        }
    },
    {
        path: '/liangzhushop/logistics',
        component: () => import('@/components/user/logistics'),
        name: 'logistics',
        meta: {
            title: '物流信息',
            keepAlive: false,
            tabbarShow: false

        }
    }
]