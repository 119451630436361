/* 登录相关接口 */
import axios from '../libs/ajaxRequest';

/* 获取验证码 */
const phoneCode = (data) => {
    return axios.request({
        url: 'auth/phone/send_verify_code',
        method: 'post',
        data
    });
};

/* 刷新token */
const refreshtoken = (data) => {
    return axios.request({
        url: `auth/refreshtoken/${data.refreshToken}`,
        method: 'get'
    });
};

/* 手机号码登录 */
const phoneLogin = (data) => {
    return axios.request({
        url: 'auth/authPhone',
        method: 'post',
        data
    });
};


export {
    phoneCode,
    refreshtoken,
    phoneLogin
}