import {List, Field, Uploader, Cell, CellGroup, Dialog, Lazyload, Skeleton, Toast, Step, Steps, AddressEdit, AddressList, Progress, Tab, Tabs, Sku, SubmitBar, Stepper, Checkbox, CheckboxGroup, Tabbar, TabbarItem, Search, Swipe, SwipeItem,  Sidebar, SidebarItem, Popup } from 'vant';

const loadimage = require('@/image/lazy.png');
export default {
    install: function (Vue) {
        Vue.use(List)
        Vue.use(Field)
        Vue.use(Uploader)
        Vue.use(Cell)
        Vue.use(CellGroup)
        Vue.use(Tabbar)
        Vue.use(Dialog)
        Vue.use(TabbarItem)
        Vue.use(Search)
        Vue.use(Swipe)
        Vue.use(SwipeItem)
        Vue.use(Sidebar)
        Vue.use(SidebarItem)
        Vue.use(Popup)
        Vue.use(Checkbox)
        Vue.use(CheckboxGroup)
        Vue.use(Stepper)
        Vue.use(SubmitBar)
        Vue.use(Sku)
        Vue.use(Tab)
        Vue.use(Tabs)
        Vue.use(Progress)
        Vue.use(AddressList)
        Vue.use(AddressEdit)
        Vue.use(Step)
        Vue.use(Steps)
        Vue.use(Toast)
        Vue.use(Skeleton)
        Vue.use(Lazyload, {
            lazyComponent: true,
            loading: loadimage,
        })
    }
};