import axios from '../libs/ajaxRequest';
/* 获取openid */
const getInfo = (data) => {
    return axios.request({
        url: `payment/wechat/openId/${data.code}`,
        method:'get'
    });
};

/* 获取微信支付信息 */
const getPayInfo = (data) => {
    return axios.request({
        url: `payment/pubpay/${data.openId}/${data.orderId}`,
        method: 'get'
    });
};

/* h5支付 */
const h5pay = (data) => {
    return axios.request({
        url: `payment/H5Pay/${data.orderId}`,
        method: 'get'
    });
};

/* 获取微信jssdk签名 */
const jsSing = () => {
    return axios.request({
        url: 'product/weixin/jsSign',
        method: 'get'
    });
};

export {
    getInfo,
    getPayInfo,
    h5pay,
    jsSing
}