export default [
    {
        path: '/liangzhushop/index',
        component: () => import('@/components/index/index'),
        name:'home',
        meta: {
            title: '首页',
            keepAlive: true,
            tabbarShow: true,
            scrollTop:0,
            active: 0
        }
    },
]