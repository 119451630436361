import axios from '../libs/ajaxRequest'

/* 店铺登录 */
const shopLogin = (data) => {
    
    return axios.request({
        url: 'auth/open/login',
        method: 'post',
        data
    });
};

/* 获取店铺信息 */
const shopInfo = (data) => {
    return axios.request({
        url: `shop/info/${data.appKey}`,
        method: 'get'
    });
};

export {
    shopLogin,
    shopInfo
}