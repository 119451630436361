export default [
    {
        path: '/liangzhushop/seckill',
        component: () => import('@/components/kingkong/seckill'),
        name: 'seckill',
        meta: {
            title: '商品秒杀',
            keepAlive: false,
            tabbarShow: false
        }
    },
    {
        path: '/liangzhushop/newest',
        component: () => import('@/components/kingkong/newest'),
        name: 'newest',
        meta: {
            title: '今日上新',
            keepAlive: false,
            tabbarShow: false
        }
    }
]