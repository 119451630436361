export default [
    {
        path: '/liangzhushop/productdetails',
        component: () => import('@/components/productDetails/index'),
        name: 'productdetails',
        meta: {
            title: '商品详情',
            keepAlive: false,
            tabbarShow: false
        }
    }
]