import Vue from 'vue'
import App from './App.vue'

import 'amfe-flexible'
import Vant from './Vant'
import router from './router'

import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false

Vue.use(Vant)
Vue.use(VueClipboard)

const isProd = process.env.VUE_APP_MODE === "production";
if (!isProd) {
  Vue.config.devtools = true;
}

router.beforeEach(async (to, from, next) => {
  
  /* console.log(to);
  console.log(to.query.integral);
  console.log(from);
  console.log(from.query.integral);
  if (from.query.integral > -1) {
    console.log(window.location)
      to.query.integral = from.query.integral + 1;
  } */
   
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  if (from.meta.keepAlive) {
    const $content = document.querySelector('.vanList');
    const scrollTop = $content ? $content.scrollTop : 0;
    from.meta.scrollTop = scrollTop;
  }
  next();
});

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
