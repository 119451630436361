<template>
  <div id="app">
    
    <keep-alive>
      <router-view :changeActive.sync="active"  v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view :changeActive.sync="active" v-if="!$route.meta.keepAlive"></router-view>
    <van-tabbar v-if="tabbarShow" v-model="active" active-color="#32aaff" inactive-color="#888888">
      <van-tabbar-item v-for="(item, index) in tabs" :key="index" @click="jump(item.routerName)">
        <span>{{item.name}}</span>
        <template #icon="props">
          <i :class="item.icon"></i>
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {shopLogin, shopInfo} from './api/shop'
import {refreshtoken} from './api/login'
import {jsSing} from './api/wxjsapi'
import wx from 'weixin-js-sdk'
export default {
  name: 'App',
  data () {
    return {
      tabbarShow: true,
      active: 0,
      tabs:[
        {name: '推荐', routerName: 'home', icon: 'iconfont icon-xingzhuang4'},
        {name: '分类', routerName: 'type', icon: 'iconfont icon-xingzhuang4'},
        {name: '购物车', routerName: 'shoppingCard', icon: 'iconfont icon-xingzhuang4'},
        {name: '我的', routerName: 'user', icon: 'iconfont icon-xingzhuang4'}
      ],
      code: '',
      shopLoginData: {}, //店铺登录入参
    }
  },
  mounted () {    
    /* setTimeout(() => {
      console.log(this.$route.query)
      const {appKey, credits, timestamp, sign, uid, integral} = this.$route.query;
      if (credits, timestamp, sign, uid) {
          let arr = {appKey, credits, timestamp, sign, uid};
          this.shopLoginData = arr;
          this.shopLogin();
      }
      localStorage.setItem('integral', integral);
    },1000)  */
    let us = window.navigator.userAgent;
    if (us.indexOf('MicroMessenger') > -1) {
      this.wxConfig();
    };

    
  },
  watch: {
    $route (v, i) {
      console.log(v);
      const {meta} = v;
      this.tabbarShow = meta.tabbarShow;
      this.active = meta.active;
      const {appKey, credits, timestamp, sign, uid} = this.$route.query;
      if (credits, timestamp, sign, uid) {
          let arr = {appKey, credits, timestamp, sign, uid};
          this.shopLoginData = arr;
          this.shopLogin();
      }
      // alert(localStorage.getItem('integral'));
    },
  },
  methods: {
    jump (name) {
      const {$router} = this;
      const {appKey, credits, timestamp, sign, uid, code, state, integral, shoplimit} = this.$route.query;
      $router.push({name, query:{appKey, credits, timestamp, sign, uid, code, state, integral, shoplimit}});
    },

    /* 店铺登录 */
    async shopLogin () {
        const {shopLoginData, $toast} = this;
        let res = await shopLogin(shopLoginData);
        const {code, data, msg} = res;
        if (code === 1) {
            this.setToken(data);
            // this.getShopInfo();
        } else if (code === 0) {
            // $toast('token过期')
            let res = await refreshtoken({refreshToken: localStorage.getItem('refreshToken')});
            const {code, data, msg} = res;
            if (code === 1) {
                this.setToken(data);
            }
        } else {
            $toast(msg);
        }
    },

    /* 存储token */
    setToken (data) {
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
    },

    /* 获取店铺信息 */
    async getShopInfo () {
        const {appKey} = this;
        let res = await shopInfo({appKey});
    },

    /* 获取微信签名 */
    async wxConfig () {
      let res = await jsSing();
      console.log(res);
      const {code, data, msg} = res;
      if (code == 1) {
        const {appId, nonceStr, signature, timestamp} = data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature,// 必填，签名
          jsApiList: ['hideOptionMenu'] // 必填，需要使用的JS接口列表
        });

        wx.ready(() => {
          console.log('111');
          wx.hideOptionMenu();
        })
      } 
    }

  }
}
</script>

<style lang="less">
@import '@/style/init.less';
*:not([class*='van']){
  .txtSize(24px);
  .p(0);
  .m(0);
  box-sizing: border-box;
}
body{
  .bgc(#f0f0f0);
}
.navbar{
    .bgc(#FFF);
    .flex;
    justify-content: space-between;
    align-items: center;
    .h(108px);
    .p(0 20px);
    .fixed;
    .w(100%);
    .t(0);
    .l(0);
    z-index:2;
    button{
        .noBor;
        .noneback;
        &:nth-child(1){
            .txtSize(28px);
            i{
                .txtSize(24px);
                .mr(10px);
            }
        }
        &:nth-child(2){
            .color(#32aaff);
            i{
                .txtSize(24px);
                .mr(8px);
            }
        }
    }
}

</style>
