import Vue from 'vue'
import Router from 'vue-router'

import Home from './home'
import Types from './types'
import ShoppingCard from './shoppingCard'
import User from './user'
import ProductDetails from './productDetails'
import KingKong from './kingkong'

Vue.use(Router)


//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: '/liangzhushop/index'
        },
        ...Home,
        ...Types,
        ...ShoppingCard,
        ...User,
        ...ProductDetails,
        ...KingKong
    ]
})