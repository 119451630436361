export default [
    {
        path: '/liangzhushop/type',
        component: () => import('@/components/types/index'),
        name: 'type',
        meta: {
            title: '分类',
            keepAlive: true,
            tabbarShow: true,
            active: 1
        }
    },
]