import axios from 'axios'
import {refreshtoken} from '../api/login'

let isRefreshing = false // 标记是否正在刷新 token
let requests = [] // 存储待重发请求的数组
console.log(process.env);
const instance = axios.create({
    // baseURL: 'https://api-mall.liangzhukeji.com/api/'
    // baseURL: 'https://api.liangzhukeji.com/api/'
    baseURL: process.env.VUE_APP_BASE_URL
});

instance.interceptors.request.use(config => {
    const {url} = config;
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;
    // if (url === 'auth/user/info' || url === 'auth/phone/send_verify_code' || url === 'auth/address/list') {
    // }
    return config;
});

instance.interceptors.response.use(
    res => {
    return res.data;
    },
    /* error => {
        if (!error.response) return Promise.reject(error);        
        const {config, status} = error.response;
        if (status == 401) {
            if (!isRefreshing) {
                isRefreshing = true;
                var token = localStorage.getItem('refreshToken');
                return refreshtoken({token})
                .then(res => {
                    setToken(res.data);
                    localStorage.setItem('accessToken', res.data.accessToken);
                    localStorage.setItem('refreshToken', res.data.refreshToken);
                    // config.headers.Authorization = `Bearer ${res.data.accessToken.tokenContent}`
                    
                    requests.forEach((cb) => {
                        // console.log(cb);
                        cb(res.data.accessToken.tokenContent)
                    })
                    requests = [] // 重新请求完清空
                    return instance(config);
                }).catch(err => {
                    return Promise.reject(err)
                }).finally(() => {
                    isRefreshing = false
                })
            } else {
                return new Promise(resolve => {
                            // 用函数形式将 resolve 存入，等待刷新后再执行
                            requests.push(token => {
                                config.headers.Authorization = `Bearer ${token}`
                                resolve(instance(config))
                            })  
                        })
            }
        }
        return Promise.reject(error);
    } */
);

export default instance;